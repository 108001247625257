import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import ImageArea from './ImageArea';
import type { root } from '../../interfaces/slices';

const styles = {
  textStack: {
    flexDirection: { xs: 'colmun', md: 'row' },
    justifyContent: 'center',
    my: { xs: '24px', md: '32px' },
    text: {
      display: 'inline-block',
      textAlign: 'center',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: '游ゴシック体',
      lineHeight: 1.714,
    },
  },
  positioning: {
    height: '100%',
    // minHeight: { xs: 500, md: 620 },
  },
  imgareaBox: {
    position: 'relative',
    maxHeight: '100%',
    minWidth: { xs: '100%' },
    minHeight: '200px',
    // opacity: '50%',
  },
};

const Positioning: React.VFC = () => {
  const { data, fileName } = useSelector((s: root) => s.imgData);
  // console.log('imgData', data, fileName);
  return (
    <Box sx={styles.positioning}>
      <Stack sx={styles.textStack}>
        <Typography sx={styles.textStack.text}>作品を飾りたい場所の</Typography>
        <Typography sx={styles.textStack.text}>
          範囲を選択してください
        </Typography>
      </Stack>
      <Box className="step3-imgarea-box" sx={styles.imgareaBox}>
        <ImageArea src={data} />
      </Box>
    </Box>
  );
};

export default Positioning;
