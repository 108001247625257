import React from 'react';
import { Layer } from 'react-konva';
import { nanoid } from 'nanoid';

import ImgKonva from './ImgKonva';
import frameImgs from './flames';

type picture = {
  img: string;
  width: number;
  height: number;
  x: number;
  y: number;
  zoom: number;
  frameNumber: 0 | 1 | 2 | 3 | 4;
};

type parts = {
  img: string;
  width: number;
  height: number;
  x: number;
  y: number;
  zoom: number;
  margin: number;
};

const PictureLayer: React.FC<picture> = ({
  width,
  height,
  x,
  y,
  zoom,
  frameNumber,
}) => {
  // console.log('he', height);
  // console.log('FF width', width);
  const margin = frameImgs[frameNumber].width;
  // console.log('m', margin);
  return (
    <Layer>
      <ImgKonva
        key={nanoid()}
        name="frame-t"
        imgUrl={frameImgs[frameNumber].t}
        width={width - margin * 2}
        height={margin / zoom}
        x={x + margin}
        y={y}
      />
      <ImgKonva
        key={nanoid()}
        name="frame-b"
        imgUrl={frameImgs[frameNumber].b}
        width={width - margin * 2}
        height={margin / zoom}
        x={x + margin}
        y={y + height - margin / zoom}
      />
      <ImgKonva
        key={nanoid()}
        name="frame-l"
        imgUrl={frameImgs[frameNumber].l}
        width={margin}
        height={height - margin * 2}
        x={x}
        y={y + margin}
      />
      <ImgKonva
        key={nanoid()}
        name="frame-r"
        imgUrl={frameImgs[frameNumber].r}
        width={margin}
        height={height - margin * 2}
        x={x + width - margin}
        y={y + margin}
      />
      <ImgKonva
        name="frame-ctl"
        imgUrl={frameImgs[frameNumber].tl}
        width={margin / zoom}
        height={margin / zoom}
        x={x}
        y={y}
      />
      <ImgKonva
        name="frame-ctr"
        imgUrl={frameImgs[frameNumber].tr}
        width={margin / zoom}
        height={margin / zoom}
        x={width + x - margin}
        y={y}
      />
      <ImgKonva
        name="frame-cbl"
        imgUrl={frameImgs[frameNumber].bl}
        width={margin / zoom}
        height={margin / zoom}
        x={x}
        y={y + height - margin / zoom}
      />
      <ImgKonva
        name="frame-cbr"
        imgUrl={frameImgs[frameNumber].br}
        width={margin / zoom}
        height={margin / zoom}
        x={width + x - margin}
        y={y + height - margin / zoom}
      />
    </Layer>
  );
};

export default PictureLayer;

{
  /* <Top
  width={width}
  x={x}
  y={y}
  zoom={zoom}
  img={frameImgs[frameNumber].t}
  margin={margin}
/>
<Bottom
  width={width}
  height={height}
  x={x}
  y={y}
  zoom={zoom}
  img={frameImgs[frameNumber].b}
  margin={margin}
/>
<Right
  width={width}
  height={height}
  x={x}
  y={y}
  zoom={zoom}
  img={frameImgs[frameNumber].r}
  margin={margin}
/>
<Left
  width={width}
  height={height}
  x={x}
  y={y}
  zoom={zoom}
  img={frameImgs[frameNumber].l}
  margin={margin}
/> */
}
