import React, { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../stores/appState";
import type { root } from "../../interfaces/slices";

import InputValidated from "../InputValidated";
import "./InputKeywords.scss";

const styles = {
  bgcolor: "#fff",
  border: "solid 1px #707070",
  borderRadius: "50px",
};

const TextInput: React.VFC = () => {
  const { keyword } = useSelector((s: root) => s.appState);
  const [isReady, setIsReady] = useState(true);
  // const { input, setInput, isReady, setIsReady } = useContext(Context);
  const dispatch = useDispatch();
  const setValue = useCallback(
    v => {
      dispatch(
        updateAppState({
          keyword: v,
        })
      );
    },
    [keyword, dispatch]
  );
  const setReady = useCallback(v => {
    // setIsReady({
    //   ...isReady,
    //   [value]: v
    // });
  }, []);

  return (
    <>
      <InputValidated
        fullWidth
        label="アーティスト名やキーワードをご記入ください"
        helperText="例：葛飾北斎 文字列は半角スペースで区切ってください"
        exp={/^(0{1}\d{9,10})$/}
        size="medium"
        value={keyword}
        setValue={setValue}
        isReady={isReady}
        setIsReady={setReady}
        className="inputValidated"
        sx={styles}
      />
    </>
  );
};

export default TextInput;
