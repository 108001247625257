import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import reactImageSize from 'react-image-size';

import type { root } from '../../interfaces/slices';

const usePictureSize = () => {
  const { pictureImg, crop } = useSelector((s: root) => s.appState);
  const frameNumber = 4;
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(150);
  const [px, setX] = useState(0);
  const [py, setY] = useState(0);
  // console.log('picture', width, height, px, py);
  const padding = 0;
  // const padding = useMemo(() => {
  //   if (frameNumber === 0 || frameNumber === 1) {
  //     return 20;
  //   }
  //   if (frameNumber === 2 || frameNumber === 3) {
  //     return 15;
  //   }
  //   return 0;
  // }, [pictureImg, crop, frameNumber]);
  // console.log('padding', padding);

  useEffect(() => {
    let unmounted = false;
    const setPsize = async () => {
      if (pictureImg === undefined) {
        // console.log('no');
        return;
      }
      if (unmounted) {
        return;
      }
      try {
        const size = await reactImageSize(pictureImg);
        const cw = crop.width - padding * 2;
        const ch = crop.height - padding * 2;
        // console.log('area', aw, ah);
        const pw = size.width;
        const ph = size.height;
        // console.log('picture-raw', pw, ph);

        // const pAsp = width / height;
        // const aAsp = aw / ah;
        // console.log('asp', aw, ah, aAsp, pAsp);

        // console.log('zoom', zoom);
        let w = cw;
        let h = ch;
        let x = px;
        let y = py;
        if (pw > ph) {
          // console.log('wider');
          const zoom = pw / cw;
          w = cw;
          h = ph / zoom;
          x = 0 + padding;
          y = (ch - h) / 2 + padding;
          // setPictureSize({ pw: aw, ph: ah * (aAsp / pAsp) });
        } else {
          // console.log('higher');
          const zoom = ph / ch;
          w = pw / zoom;
          h = ch;
          x = (cw - w) / 2 + padding;
          y = 0 + padding;
          // console.log('what', w, h, x, y, zoom);
          // setPictureSize({ pw: aw * (pAsp / aAsp), ph: ah });
        }
        // console.log('set', w, h, x, y);
        setWidth(w);
        setHeight(h);
        setX(x);
        setY(y);
        // console.log('result', w, h);
      } catch {
        console.log('error');
      }
    };
    setPsize();
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [pictureImg, crop, frameNumber]);
  return { pw: width, ph: height, px, py };
};

export default usePictureSize;
