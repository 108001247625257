import React, { useState, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
// import type { root } from "../../interfaces/slices";
// import { updateAppState } from "../../stores/appState";

import SubHeader from "./SubHeader";
import RoomImgZone from "../Picture";
import ImgSelect from "../ImgSelect";
import BottomButtons from "./BottomButtons";

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    width: "100%",
    // maxWidth: 'md',
    overflow: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  subTexts: {
    display: { xs: "block", md: "none" },
    p: 1.5,
    bgcolor: "rgba(248,248,248,0.1)",
  },
  subText: {
    fontSize: "0.8em",
    color: "rgba(248,248,248, 0.7)",
  },
};

const Zooming: React.FC = () => {
  const [movile, setMovile] = useState(false);
  const stageRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setMovile(true);
    } else {
      setMovile(false);
    }
  }, []);

  return (
    <Box className="step4-container" sx={styles.container}>
      <SubHeader />
      <Box className="step4-content" sx={styles.content}>
        <RoomImgZone ref={stageRef} />
      </Box>
      <Box className="" sx={styles.subTexts}>
        <Typography sx={styles.subText}>
          ＊あくまでもイメージで、実際の縮尺とは異なります。
        </Typography>
      </Box>
      {movile ? <ImgSelect /> : null}
      <Box>
        <BottomButtons current={stageRef.current} />
      </Box>
    </Box>
  );
};

export default Zooming;
