import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import appState from "./appState";
import imgData from "./imgData";
import resultImg from "./resultImg";
import topSnackBar from "./topSnackBar";

const reducer = combineReducers({
  appState,
  imgData,
  resultImg,
  topSnackBar,
});

const store = configureStore({ reducer });

export default store;
