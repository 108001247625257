import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";

import { updateAppState } from "../stores/appState";
import type { root } from "../interfaces/slices";
// import './Paging.scss';

const styles = {
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    bgcolor: "#E0E0E0",
    px: 2,
  },
  buttonRw: {
    color: "#fff",
    bgcolor: "#707070",
    borderRadius: "30px",
    width: { xs: 136, md: 168 },
    height: 36,
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
  buttonFf: {
    color: "#fff",
    bgcolor: "#348ea8",
    borderRadius: "30px",
    width: { xs: 136, md: 168 },
    height: { xs: 36, md: 36 },
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
  none: {
    display: "none",
  },
};

const StepButton: React.VFC = () => {
  // const { activeStep, setActiveStep, completed, setCompleted } =
  //   useContext(Context);
  const { step } = useSelector((s: root) => s.appState);
  // console.log('step', step);
  const dispatch = useDispatch();
  const handleAdvance = () => {
    dispatch(
      updateAppState({
        step: step + 1,
      })
    );
  };
  const handleBackword = () => {
    dispatch(
      updateAppState({
        step: step - 1,
      })
    );
  };
  return (
    <Stack className="paging-container" direction="row" sx={styles.container}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleBackword}
        sx={step === 0 ? styles.none : styles.buttonRw}
        className="beforeButton"
      >
        戻る
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleAdvance}
        sx={styles.buttonFf}
        className="nextButton"
      >
        次へ
      </Button>
    </Stack>
  );
};

export default StepButton;
