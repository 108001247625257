import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import HeaderStep from "./HeaderStep";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import PagingFirst from "./PagingFirst";
import Paging from "./Paging";
import Step4 from "./Step4";
import type { root } from "../interfaces/slices";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    // overflowY: "scroll",
    // position: "relative",
    // width: "100%",
    // height: "100%",
    // boxSizing: "border-box",
    // display: "flex",
    // flexDirection: "column",
    height: "100%",
  },
  stepper: {
    // position: "relative",
    // boxSizing: "border-box",
    maxWidth: { xs: "95vw", md: "sm" },
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
    flex: 1,
    bgcolor: "#333",
    // maxWidth: "md",
    // mx: "auto",
    // pb: 15,
    // overflowY: "scroll",
    // msOverflowStyle: { xs: 'none', md: 'auto' },
    // scrollbarWidth: { xs: 'none', md: 'auto' },
    // '::-webkit-scrollbar': {
    // display: { xs: 'none', md: 'block' },
    // },
  },
  content1: {
    position: "relative",
    width: "100%",
    height: "100%",
    flex: 1,
    overflowY: "scroll",
    // pb: "64px",
  },
  bottom: {
    width: "100%",
  },
};

const Content: React.FC = () => {
  const { step } = useSelector((s: root) => s.appState);
  return (
    <Box className="content-container" sx={styles.container}>
      {step < 3 ? (
        <>
          <Box className="content-stepper" sx={styles.stepper}>
            <HeaderStep />
          </Box>
          <Box className="content-content" sx={styles.content1}>
            {step === 0 ? <Step1 /> : null}
            {step === 1 ? <Step2 /> : null}
            {step === 2 ? <Step3 /> : null}
          </Box>
        </>
      ) : (
        <Step4 />
      )}
      <Box className="content-bottom" sx={styles.bottom}>
        {step === 0 ? <PagingFirst /> : null}
        {step === 1 || step === 2 ? <Paging /> : null}
      </Box>
    </Box>
  );
};

export default Content;
//

// {step === 0 ? <PagingFirst /> : <Paging />}
