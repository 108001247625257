import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Divider, Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import fileDownload from 'js-file-download';

import DialogContent from './DialogContent';
import { requestPost } from '../../utils/requestMethods';
import { updateAppState } from '../../stores/appState';
// import { updateModal } from '../stores/topData.modal';
import type { root } from '../../interfaces/slices';
import type {
  postRoomImg,
  postRoomImgResponse,
} from '../../interfaces/apiCall';

const styles = {
  dialog: {
    // color: 'blue',
    bgcolor: 'rgba(0,0,0,0.6)',
    p: 2,
  },
  title: {
    color: '#00CCCC',
    textAlign: 'center',
  },
  text: {
    color: '#fff',
  },
  buttonS: {
    color: '#fff',
    bgcolor: '#348ea8',
    borderRadius: '30px',
    width: { xs: 136, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: '0.7rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
    hanbai: {
      bgcolor: '#f1cb4e',
    },
  },
  buttonL: {
    color: '#fff',
    bgcolor: '#348ea8',
    borderRadius: '30px',
    width: { xs: '100%', md: 220 },
    height: { xs: 36, md: 36 },
    fontSize: '0.7rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
  },
  button: {
    color: '#fff',
    bgcolor: '#348ea8',
    borderRadius: '30px',
    width: { xs: 136, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: '1rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
    hanbai: {
      bgcolor: '#f1cb4e',
    },
  },
  caseS: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-btween',
    width: '100%',
    my: 4,
  },
  caseL: {
    display: 'flex',
    justifyContent: 'space-between',
    my: 4,
  },
  bagian: {
    bgcolor: '#0099CC',
  },
  green: {
    bgcolor: '#339966',
  },
  red: {
    bgcolor: '#990033',
  },
  yellow: {
    color: 'black',
    bgcolor: '#f1cb4e',
  },
  white: {
    color: '#000',
    bgcolor: '#fff',
  },
};

const DownloadButton = ({ current, setOpen }) => {
  const { data, fileName } = useSelector((s: root) => s.resultImg);
  const { frameNumber } = useSelector((s: root) => s.appState);
  const [frameImg, setFrameImg] = useState<string | false>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // console.log('download img', fileName, data);
  // console.log('cubu', current);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // console.log("isready", isReady);
  // const loginData = useSelector((s: root) => s.loginData);

  const dispatch = useDispatch();

  const handleClick = useCallback(async () => {
    const imgUri = current !== null ? current.toDataURL() : data;
    const d = dataURLtoFile(imgUri, fileName);
    // console.log('budata', imgUri);
    // console.log('dt', d);
    fileDownload(d, fileName);
    setOpen(true);
    setLoading(true);
    const url =
      'https://asia-northeast1-virtual-gallery-348811.cloudfunctions.net/F3_RoomImgs';
    const params: postRoomImg = {
      data: imgUri,
      fileName,
    };
    type responseType = {
      result: boolean;
      data?: postRoomImgResponse;
    };
    try {
      const response: responseType = await requestPost({ url, params });
      console.log('login post', response);
      setLoading(false);
      if (response.data.result) {
        console.log('post result');
      } else {
        console.log(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [data, fileName, current]);

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      sx={{ ...styles.button }}
    >
      画像を保存
    </Button>
  );
};

const PictDownloadButton: React.VFC<{ current: React.Ref<null> }> = ({
  current,
}) => {
  const [open, setOpen] = useState(false);
  // console.log('current', current);
  const { url } = useSelector((s: root) => s.appState);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handlePasing = (step: number) => {
    dispatch(
      updateAppState({
        step,
      }),
    );
  };

  return (
    <>
      <DownloadButton current={current} setOpen={setOpen} />
      <DialogContent url={url} open={open} setOpen={setOpen} />
    </>
  );
};

export default PictDownloadButton;
