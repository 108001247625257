import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Step, StepButton, Stepper } from "@mui/material";

import { updateAppState } from "../stores/appState";
import type { root } from "../interfaces/slices";
import "./HeaderStep.scss";

const steps = [
  { step: 0, value: "絵画の種類を選ぶ" },
  { step: 1, value: "部屋の画像をアップロード" },
  { step: 2, value: "絵画を見る場所を選択する" },
  { step: 3, value: "飾りたい絵画を選ぶ" },
];

const HeaderStep: React.FC = () => {
  const appState = useSelector((s: root) => s.appState);
  const { step } = appState;

  const dispatch = useDispatch();
  const handleClick = (i: number) => {
    dispatch(updateAppState({ step: i }));
  };

  return (
    <Stepper activeStep={step} alternativeLabel nonLinear className="stepLine">
      {steps.map((step, index) => (
        <Step key={step.step}>
          <StepButton
            color="inherit"
            onClick={() => handleClick(index)}
            className="setupButton"
          >
            {step.value}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default HeaderStep;
