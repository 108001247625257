import React from 'react';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
import { Button, Box, Stack, Typography } from '@mui/material';
// import type { root } from '../../interfaces/slices';
import { updateAppState } from '../../stores/appState';

import FrameSelect from './FrameSelect';

const styles = {
  stack: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
    p: 2,
    px: { md: 4 },
  },
  subTexts: {
    display: { xs: 'none', md: 'block' },
    p: 1.5,
    // bgcolor: 'rgba(248,248,248,0.1)',
  },
  subText: {
    fontSize: '0.8em',
    color: 'rgba(248,248,248, 0.7)',
  },
  button: {
    display: { xs: 'block', md: 'none' },
    color: '#fff',
    bgcolor: 'rgba(248,248,248, 0.3)',
    borderRadius: '30px',
    width: { xs: 100, md: 168 },
    height: { xs: 32, md: 36 },
    fontSize: '0.8rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
    hanbai: {
      bgcolor: '#f1cb4e',
    },
  },
};

const SubHeader: React.VFC = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(updateAppState({ step: 2 }));
  };

  return (
    <Stack sx={styles.stack}>
      <Box className="" sx={styles.subTexts}>
        <Typography sx={styles.subText}>絵画プレビューシステム</Typography>
        <Typography sx={styles.subText}>＊あくまでもイメージです。</Typography>
        <Typography sx={styles.subText}>
          実際の縮尺とは異なりますのでご了承ください。
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        sx={styles.button}
      >
        変更する
      </Button>
      <FrameSelect />
    </Stack>
  );
};

export default SubHeader;
