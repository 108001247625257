import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Box, Button, Stack, Typography } from "@mui/material";
import { updateImgData } from "../../stores/imgData";
import icon from "../../imgs/img_cloud.png";
//
const baseStyle = {
  // width: '100%',
  maxWidth: { xs: "90vw", md: "sm" },
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // margin: "32px",
  padding: "20px",
  width: "100%",
  boxSizing: "border-box",
  borderWidth: "3px",
  borderRadius: 8,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "rgba(248,248,248,0.1)",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const styles = {
  stack: {
    alignItems: "center",
  },
  upload: {
    textAlign: "center",
    fontSize: "1.8em",
    fontWeight: "bold",
    mb: 1,
  },
  text: {
    textAlign: "center",
    fontWeight: "bold",
  },
  img: {
    width: 150,
    mt: 1,
  },
  and: {
    display: { xs: "none", md: "block" },
    textAlign: "center",
    fontWeight: "bold",
  },
  desk: {
    display: { xs: "block", md: "none" },
    textAlign: "center",
    fontWeight: "bold",
  },
  button: {
    mx: "auto",
    my: 1,
    color: "rgba(0,0,0,0.55)",
    bgcolor: "#bdbdbd",
    borderRadius: "30px",
    width: { xs: 240, md: 168 },
    height: 56,
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
};

const controlledStyle = {
  active: {
    borderColor: "#2196f3",
  },
  accept: {
    borderColor: "#00e676",
  },
  reject: {
    borderColor: "#ff1744",
  },
};

const DropZone: React.VFC = () => {
  // const { setIsFile, setFileData, setFileName } = useContext(FileInputContext);
  const dispatch = useDispatch();
  const onDrop = useCallback(acfiles => {
    acfiles.forEach(file => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        const fname = file.name;
        dispatch(
          updateImgData({
            data: binaryStr,
            fileName: fname,
          })
        );
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".jpg, .jpeg, .png" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? controlledStyle.active : {}),
      ...(isDragAccept ? controlledStyle.accept : {}),
      ...(isDragReject ? controlledStyle.reject : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path}-{file.size}
      bytes
    </li>
  ));

  // useEffect(() => {
  //   if (acceptedFiles.length > 0) {
  //     setIsFile(true);
  //   } else {
  //     setIsFile(false);
  //   }
  // }, [acceptedFiles]);

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <Stack className="upload-container" gap={2} sx={styles.stack}>
        <Box sx={styles.img}>
          <img src={icon} alt="upload" width="100%" height="100%" />
          <Typography sx={styles.upload}>UPLOAD</Typography>
        </Box>
        <Box>
          <Typography sx={styles.text}>作品を飾りたいお部屋の写真を</Typography>
          <Typography sx={styles.desk}>アップロードしてください</Typography>
          <Typography sx={styles.and}>ここにドロップしてください</Typography>
        </Box>
        <Typography sx={styles.and}>または</Typography>
        <Button variant="contained" color="secondary" sx={styles.button}>
          ファイルから選択
        </Button>
      </Stack>
    </div>
  );
};

export default DropZone;
// <Box></Box>
//<Stack gap={2}></Stack>
