import React, { useCallback } from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../stores/appState";
import type { root } from "../../interfaces/slices";
import "./Sizes.scss";

const styles = {
  chip: {
    mx: 1,
    fontSize: "13px",
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1,
    letterSpacing: "0.03em",
    width: "112px",
    height: "33px",
  },
  on: {
    bgcolor: "#348EA8!important",
  },
  off: {
    bgcolor: "#5A5A5A",
  },
};

const ja = {
  huge: "それ以上",
  ll: "LL",
  l: "L",
  m: "M",
  s: "S",
  ss: "SS",
};

const Types: React.VFC = () => {
  const check = useSelector((s: root) => s.appState.sizes);
  // console.log("types", types);
  const dispatch = useDispatch();
  const handleClick = useCallback(
    key => {
      dispatch(
        updateAppState({
          sizes: { ...check, all: false, [key]: !check[key] },
        })
      );
    },
    [check, dispatch]
  );
  const clickAll = () => {
    dispatch(
      updateAppState({
        sizes: {
          all: !check.all,
          huge: !check.all,
          ll: !check.all,
          l: !check.all,
          m: !check.all,
          s: !check.all,
          ss: !check.all,
        },
      })
    );
  };
  return (
    <Paper className="choose-work-card">
      <Typography className="work-type">サイズを選ぶ</Typography>
      <Chip
        label="すべて"
        onClick={clickAll}
        sx={{ ...styles.chip, ...(check.all ? styles.on : styles.off) }}
        className="chipAll"
      />
      <div className="work-chipOthers-wrapper">
        {Object.keys(ja).map((key: string) => (
          <Chip
            key={key}
            label={ja[key]}
            onClick={() => handleClick(key)}
            sx={{ ...styles.chip, ...(check[key] ? styles.on : styles.off) }}
            className="chipOthers"
          />
        ))}
      </div>
      <p className="sizeDetail">
        ※ それ以上 = 40号〜 / LL = 20〜40号 / L = 12〜15号 / M = 8〜10号 / S =
        4〜6号 / SS = 0〜3号
      </p>
    </Paper>
  );
};

export default Types;
