import React from 'react';
import { Layer } from 'react-konva';

import ImgKonva from './ImgKonva';

// const roomImg =
//   'https://st.depositphotos.com/1047404/1878/i/950/depositphotos_18782653-stock-photo-empty-brown-room.jpg';
//
// const pictureImg =
//   'https://baseec-img-mng.akamaized.net/images/item/origin/91663828fe653cf94b6ca9c3164e5a0d.jpg?imformat=generic&q=90&im=Resize,width=640,type=normal';

type props = {
  img: string;
  width: number;
  height: number;
};

const RoomLayer: React.FC<props> = ({ img, width, height }) => {
  // console.log('sta', ref);
  return (
    <Layer>
      <ImgKonva imgUrl={img} width={width} height={height} />
    </Layer>
  );
};

export default RoomLayer;
