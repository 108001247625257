import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import useGetQuery from "../hooks/useGetQuery";
import {
  requestGet,
  promiseResolver,
  typeRequest,
} from "../utils/requestMethods";
import { updateAppState } from "../stores/appState";
import type { root } from "../interfaces/slices";
import { updateSnackBar } from "../stores/topSnackBar";
import type { typeGetImgs, getImgsResponse } from "../interfaces/apiCall";

const styles = {
  buttonCase: {
    justifyContent: "flex-end",
    alignItems: "center",
    height: 64,
    bgcolor: "#E0E0E0",
    px: 2,
  },
  buttonRw: {
    color: "#fff",
    bgcolor: "#707070",
    borderRadius: "30px",
    width: { xs: 136, md: 168 },
    height: 36,
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
  buttonFf: {
    color: "#fff",
    bgcolor: "#348ea8",
    borderRadius: "30px",
    width: { xs: 136, md: 168 },
    height: { xs: 36, md: 36 },
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
  none: {
    display: "none",
  },
};

// const getImgs = async ({ keyword, types, colors, sizes }) => {
//   const url =
//     "https://asia-northeast1-virtual-gallery-348811.cloudfunctions.net/F1_GET_imgs";
//   const requestParams: typeRequest = {
//     url,
//     params: { keyword, types, colors, sizes },
//   };
//   const response: getImgsResponse = await promiseResolver(
//     requestGet(requestParams)
//   );
//   console.log("response", response);
//   return response;
// };

const StepButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const { types, colors, sizes, keyword, step } = useSelector(
    (s: root) => s.appState
  );
  const { typesData, colorsData, sizesData } = useGetQuery();
  console.log("types", typesData);

  useEffect(() => {
    setIsReady(true);
  }, [types, colors, sizes, keyword, step]);

  const dispatch = useDispatch();

  const handleClick = async () => {
    const url =
      "https://asia-northeast1-virtual-gallery-348811.cloudfunctions.net/F1_GET_imgs";
    const requestParams: typeRequest = {
      url,
      params: {
        keyword,
        types: typesData,
        colors: colorsData,
        sizes: sizesData,
      },
    };
    setLoading(true);
    const response: getImgsResponse = await promiseResolver(
      requestGet(requestParams)
    );
    console.log("response", response);
    setLoading(false);
    if (response.result) {
      console.log("success", response.payload);
      dispatch(updateAppState({ pictureImgs: response.payload }));
      if (response.payload.length > 0) {
        setIsReady(true);
        dispatch(
          updateAppState({
            pictureImg: response.payload[0].img,
            pictureName: response.payload[0].title,
          })
        );
        dispatch(
          updateAppState({
            step: step + 1,
          })
        );
      } else {
        setIsReady(false);
        dispatch(
          updateSnackBar({
            open: true,
            comment:
              "該当の絵画はございません。再度選択条件を変更してください。",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        updateSnackBar({
          open: true,
          comment: "サーバー通信に失敗しました",
          severity: "error",
        })
      );
    }
  };

  return (
    <Stack direction="row" sx={styles.buttonCase}>
      <LoadingButton
        className="nextButton"
        variant="contained"
        color="secondary"
        disabled={!isReady || loading}
        loading={loading}
        onClick={handleClick}
        sx={styles.buttonFf}
      >
        次へ
      </LoadingButton>
    </Stack>
  );
};

export default StepButton;
