import { createSlice } from "@reduxjs/toolkit";

export type kindType =
  | {
      all: boolean;
      fukei: boolean;
      kaikei: boolean;
      seibutsu: boolean;
      jinbutsu: boolean;
      doubutsu: boolean;
      cyusyou: boolean;
      ongaku: boolean;
      fantasy: boolean;
    }
  | {};

export type colorType =
  | {
      all: boolean;
      shiro: boolean;
      kuro: boolean;
      ao: boolean;
      midori: boolean;
      ki: boolean;
      aka: boolean;
      murasaki: boolean;
      pink: boolean;
      cya: boolean;
      daidai: boolean;
    }
  | {};

export type appState = {
  content: "steps" | "upload";
  step: 0 | 1 | 2 | 3;
  pictureImgs: string[] | [];
  pictureImg: string | undefined;
  pictureName: string;
  crop: {
    unit: "px" | "%";
    width: number;
    height: number;
    x: number;
    y: number;
  };
  types: {
    all: boolean;
    fukei: boolean;
    kaikei: boolean;
    seibutsu: boolean;
    jinbutsu: boolean;
    doubutsu: boolean;
    cyusyou: boolean;
    ongaku: boolean;
    fantasy: boolean;
  };
  colors: colorType;
  sizes: {
    all: boolean;
    huge: boolean;
    ll: boolean;
    l: boolean;
    m: boolean;
    s: boolean;
    ss: boolean;
  };
  keyword: string;
  query: string;
  frameNumber: 0 | 1 | 2 | 3 | 4;
  url: string;
};

const initialState: appState = {
  content: "steps",
  step: 0,
  pictureImgs: [],
  pictureImg: undefined,
  pictureName: "",
  crop: { unit: "px", x: 50, y: 25, width: 200, height: 150 },
  types: {
    all: false,
    fukei: false,
    kaikei: false,
    seibutsu: false,
    jinbutsu: false,
    doubutsu: false,
    cyusyou: false,
    ongaku: false,
    fantasy: false,
  },
  colors: {
    all: false,
    shiro: false,
    kuro: false,
    ao: false,
    midori: false,
    ki: false,
    aka: false,
    murasaki: false,
    pink: false,
    cya: false,
    daidai: false,
  },
  sizes: {
    all: false,
    huge: false,
    ll: false,
    l: false,
    m: false,
    s: false,
    ss: false,
  },
  keyword: "",
  query: "",
  frameNumber: 4,
  url: "https://enartsu.theshop.jp/",
};

const slice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    updateAppState: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { updateAppState } = slice.actions;

export default slice.reducer;
