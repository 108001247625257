import React from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import logo from "../imgs/logo_01.jpg";
// import './Header.scss';
// import './Button.scss';

const styles = {
  header: {
    position: "relative",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // px: '12px',
    height: 64,
    bgcolor: { xs: "#fff", md: "rgba(0, 0, 0, 0.04)" },
    zIndex: 10,
    px: { xs: 1, md: 2 },
  },
  logo: {
    // display: 'flex',
    // flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "center",
    width: { xs: 150, md: 164 },
    // maxWidth: '150px',
    bgcolor: "#fff",
    borderRight: { md: "solid 1px rgba(0, 0, 0, 0.1)" },
    textBox: {
      ml: "8px",
      mt: "12px",
    },
  },
  img: {
    width: "48px",
  },
  cName: {
    textAlign: "left",
    fontFamily: "Adobe Naskh, serif",
    fontSize: { xs: "14px !important;", md: "28px" },
    fontWeight: "bold",
    letterSpacing: "0.02em",
    mt: "2px",
  },

  cSub: {
    fontFamily: "Adobe Naskh, serif",
    // overflow: 'visible',
    width: 300,
    transform: "scale(0.45)",
    fontSize: "17px",
    fontWeight: "bold",
    letterSpacing: "0.02em",
  },
  transX: {
    // left: '50%',
    transform: "translate(-82px, -8px)",
    height: 26,
  },
  title: {
    maxWidth: "150px",
    fontFamily: "Hiragino Kaku Gothic ProN",
    color: "#363636",
    mt: "12px",
    main: {
      display: "inline-block",
      fontFamily: "inherit",
      fontSize: "10px",
      fontWeight: 600,
      color: "inherit",
      letterSpacing: 0,
    },
    sub: {
      width: 300,
      fontFamily: "inherit",
      fontSize: "15px",
      color: "inherit",
      transform: "scale(0.45)",
    },
    tranX: {
      transform: "translate(-82px, -8px)",
      height: 26,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    button: {
      width: { md: "120px" },
      px: 0,
      bgcolor: "#4188D9 !important",
      borderRadius: "25px !important",
    },
  },
};

const Logo: React.FC = () => {
  return (
    <Stack direction="row" sx={styles.logo}>
      <Box>
        <img src={logo} alt="corp-icon" width="36px" height="36px" />
      </Box>
      <Box sx={styles.logo.textBox}>
        <Typography sx={styles.cName}>Gallery Enatsu</Typography>
        <Box sx={styles.transX}>
          <Typography sx={styles.cSub}>Heart-warming Art Gallery</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

const Title: React.VFC = () => {
  return (
    <Box sx={styles.title}>
      <Typography sx={styles.title.main}>
        絵画ROOMシミュレーター
        <Divider sx={{ my: "3px" }} />
      </Typography>

      <Box sx={styles.title.tranX}>
        <Typography sx={styles.title.sub}>
          あなたのお部屋に合った絵画を選びます
        </Typography>
      </Box>
    </Box>
  );
};

const SubTitle: React.VFC = () => (
  <Typography className="Typography sub-text" sx={styles.cSub}>
    Heart-warming Art Gallery
  </Typography>
);

const Header: React.VFC = () => {
  return (
    <Stack direction="row" sx={styles.header}>
      <Logo />
      <Title />

      <Box sx={styles.button}>
        <a
          href="https://www.enartsu.co.jp/howtouse/"
          style={{ textDecoration: "none", color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            sx={styles.button.button}
            onClick={() => console.log("onckik")}
          >
            使い方
          </Button>
        </a>
      </Box>
    </Stack>
  );
};
export default Header;

{
  /* <Box className="col-5 col-md-3 logo-area" sx={styles.logo}>
  <img src={logo} alt="icon" />
  <Box className="company-title">
    <Typography className="Typography" sx={styles.cName}>
      Gallery Enatsu
    </Typography>
    <Box sx={styles.transX}>
      <Typography className="" sx={styles.cSub}>
        Heart-warming Art Gallery
      </Typography>
    </Box>
  </Box>
</Box> */
}
{
  /* className="row no-gutters header-top" */
}
