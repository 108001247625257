import React, { useCallback } from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../stores/appState";
import type { root } from "../../interfaces/slices";
import type {colorType} from '../../stores/appState'
// import "./Color.scss";

const styles = {
  chip: {
    mx: 1,
    fontSize: "13px",
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1,
    letterSpacing: "0.03em",
    width: "112px",
    height: "33px"
  },
  on: {
    bgcolor: "#348EA8!important"
  },
  off: {
    bgcolor: "#5A5A5A"
  }
};

const ja = {
  shiro: "白色",
  kuro: "黒色",
  ao: "青色",
  midori: "緑色",
  ki: "黄色",
  aka: "赤色",
  murasaki: "紫色",
  pink: "ピンク",
  cya: "茶",
  daidai: "橙"
} as colorType;



const Types: React.VFC = () => {
  const check:colorType = useSelector((s: root) => s.appState.colors);
  // console.log("types", types);
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (key:keyof colorType) => {
      dispatch(
        updateAppState({
          colors: { ...check, all: false, [key]: !check[key] }
        })
      );
    },
    [check, dispatch]
  );
  const clickAll = () => {
    if (check === {}) {
      return
    }
    dispatch(
      updateAppState({
        colors: {
          all: !check.all,
          shiro: !check.all,
          kuro: !check.all,
          ao: !check.all,
          midori: !check.all,
          ki: !check.all,
          aka: !check.all,
          murasaki: !check.all,
          pink: !check.all,
          cya: !check.all,
          daidai: !check.all
        }
      })
    );
  };
  return (
    <Paper className="choose-work-card">
      <Typography className="work-type">色彩を選ぶ</Typography>
      <Chip
        label="すべて"
        onClick={clickAll}
        sx={{ ...styles.chip, ...(check.all ? styles.on : styles.off) }}
        className="chipAll"
      />
      <div className="work-chipOthers-wrapper">
        {Object.keys(ja).map((key:string) => (
          <Chip
            key={key}
            label={ja[key]}
            onClick={() => handleClick(key)}
            sx={{ ...styles.chip, ...(check[key] ? styles.on : styles.off) }}
            className="chipOthers"
          />
        ))}
      </div>
    </Paper>
  );
};

export default Types;
