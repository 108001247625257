import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import ReactCrop from "react-image-crop";

import useRoomSize from "../../Picture/useRoomSize";
import { updateAppState } from "../../../stores/appState";
import type { root } from "../../../interfaces/slices";
import "react-image-crop/src/ReactCrop.scss";
import "./style.crop.scss";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    boxSising: "border-box",
    width: "100%",
    height: "100%",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSising: "border-box",
    width: "100%",
    height: "100%",
  },
};

type crop = {
  unit: "px" | "%";
  x: number;
  y: number;
  width: number;
  height: number;
};

const ImageArea: React.FC<{ src: string }> = ({ src }) => {
  const { crop } = useSelector((s: root) => s.appState);
  const { rw, rh } = useRoomSize();
  console.log("position room size", rw, rh);
  const dispatch = useDispatch();
  const handleCahange = (c: crop) => {
    // console.log(c);
    dispatch(updateAppState({ crop: c }));
  };
  const Addon = () => {
    return (
      <Box sx={styles.modal}>
        <Typography>ドラッグして</Typography>
        <Typography>サイズを変更してください</Typography>
      </Box>
    );
  };

  return (
    <Box className="step3-wrapper" sx={styles.wrapper}>
      <Box className="step3-container" width={rw} height={rh}>
        <ReactCrop
          crop={crop}
          onChange={handleCahange}
          renderSelectionAddon={Addon}
          className="image-area"
        >
          <img
            className="position-img"
            src={src}
            alt="noImage"
            width={rw}
            height={rh}
          />
        </ReactCrop>
      </Box>
    </Box>
  );
};

export default ImageArea;
