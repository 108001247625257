import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Slide, Snackbar } from "@mui/material";

import { updateSnackBar } from "../stores/topSnackBar";
import type { root } from "../interfaces/slices";

const TransitionLeft: React.FC = props => {
  return <Slide {...props} direction="right" />;
};

const TopSnackBar: React.FC = () => {
  const { open, comment, severity } = useSelector((s: root) => s.topSnackBar);
  console.log("snackBar status", open, comment, severity);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updateSnackBar({
        open: false,
        comment: "",
      })
    );
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
    >
      <Alert severity={severity} onClose={handleClose}>
        {comment}
      </Alert>
    </Snackbar>
  );
};

export default TopSnackBar;
