import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import fileDownload from "js-file-download";

import PictDownloadButton from "../PictDownloadButton";
import type { root } from "../../interfaces/slices";
import { updateAppState } from "../../stores/appState";

const styles = {
  bottomButtons: {
    flexDirection: "row",
  },
  deskTop: {
    display: { xs: "none", md: "flex" },
    flexGrow: 2,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: 100,
    bgcolor: "rgba(248,248,248,0.75)",
  },
  buttonCase: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: { xs: 64, md: 100 },
    bgcolor: "#E0E0E0",
  },
  button: {
    color: "#fff",
    bgcolor: "#348ea8",
    borderRadius: "30px",
    width: { xs: 136, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: "1rem",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
    hanbai: {
      bgcolor: "#f1cb4e",
    },
  },
  buttonGray: {
    bgcolor: "rgba(0,0,0,0.55)",
    borderRadius: "30px",
    width: { xs: 136, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: "1rem",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
    hanbai: {
      bgcolor: "#f1cb4e",
    },
  },
};

const BottomButtons: React.VFC = ({ current }) => {
  const { url } = useSelector((s: root) => s.appState);
  const { data } = useSelector((s: root) => s.imgData);
  // console.log('curre', current);
  // console.log('url', url);
  // console.log('data', data);
  // console.log('file', fileDownload);
  const dispatch = useDispatch();
  const handlePasing = (step: number) => {
    dispatch(
      updateAppState({
        step,
      })
    );
  };
  const handleClick = () => {
    fileDownload(data, "test.jpg");
  };

  return (
    <Stack className="" sx={styles.bottomButtons}>
      <Stack className="button-case" sx={styles.deskTop}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handlePasing(0)}
          sx={styles.buttonGray}
        >
          作品の種類を変更
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handlePasing(1)}
          sx={styles.buttonGray}
        >
          部屋画像を変更
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handlePasing(2)}
          sx={styles.buttonGray}
        >
          配置位置を変更
        </Button>
      </Stack>
      <Stack className="" sx={styles.buttonCase}>
        <PictDownloadButton current={current} />
        <a
          href={url}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ ...styles.button, ...styles.button.hanbai }}
          >
            販売サイトへ
          </Button>
        </a>
      </Stack>
    </Stack>
  );
};

export default BottomButtons;
//
{
  /* <a
  id="download-img"
  href={data}
  style={{ textDecoration: 'none' }}
  download="test.jpg"
> */
}
