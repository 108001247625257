import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import * as konva from 'react-konva';

// import {updateAppState}

type props = {
  imgUrl: string;
  setReady: () => void;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  id?: string;
  name?: string;
  opacity?: number;
  crop?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  scale?: {
    x: number;
    y: number;
  };
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
  offset?: { x: number; y: number };
  offsetX?: number;
  offsetY?: number;
};

const crossOrigin = (url: string) => {
  const co = 'https://api.codetabs.com/v1/proxy?quest=';
  const src = co + url;
  const img_obj = new Image();
  img_obj.crossOrigin = 'anonymous';
  img_obj.onload = function () {};
  img_obj.src = src;
};

const imgPromise = (url: string) => {
  return new Promise(resolve => {
    const co = 'https://api.codetabs.com/v1/proxy?quest=';
    const src = co + url;
    const img_obj = new Image();
    img_obj.crossOrigin = 'anonymous';
    img_obj.src = src;
    img_obj.onload = () => {
      resolve(img_obj);
    };
  });
};

const ImgKonva: React.FC<props> = ({
  setOn,
  setReady,
  imgUrl,
  x,
  y,
  width,
  height,
  id,
  name,
  opacity,
  crop,
  scale,
  scaleX,
  scaleY,
  rotation,
  offset,
  offsetX,
  offsetY,
}) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const KonvaImg = konva.Image;
  // const dispatch = useDispatch();
  useEffect(() => {
    imgPromise(imgUrl).then(img => {
      setImage(img);
      setOn(false);
      setReady(true);
      // dispatch(updateAppState({ ready: true }));
      // console.log('set Image', img);
    });
    return () => {
      setReady(false);
      setOn(true);
    };
  }, [imgUrl]);
  return (
    <KonvaImg
      image={image}
      x={x}
      y={y}
      width={width}
      height={height}
      id={id}
      name={name}
      opacity={opacity}
      crop={crop}
      scale={scale}
      scaleX={scaleX}
      scaleY={scaleY}
      rotation={rotation}
      offset={offset}
      offsetX={offsetX}
      offsetY={offsetY}
    />
  );
};
export default ImgKonva;
