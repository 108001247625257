import { useMemo } from "react";
import { useSelector } from "react-redux";

import filters from "../filters.json";
// import { updateAppState } from '../stores/appState';
import type { root } from "../interfaces/slices";

export default () => {
  const { keyword, types, colors, sizes } = useSelector(
    (s: root) => s.appState
  );
  const typesData = useMemo(() => {
    const words = Object.keys(types).filter(
      k => types[k] === true && k !== "all"
    );
    return words.map(w => filters.types[w].keyword).toString();
  }, [types]);
  // console.log('typesData', typesData);
  const colorsData = useMemo(() => {
    const words = Object.keys(colors).filter(
      k => colors[k] === true && k !== "all"
    );
    return words.map(w => filters.colors[w].keyword).toString();
  }, [colors]);
  // console.log('colorsData', colorsData);
  const sizesData = useMemo(() => {
    const words = Object.keys(sizes).filter(
      k => sizes[k] === true && k !== "all"
    );
    return words.map(w => filters.sizes[w].keyword).toString();
  }, [sizes]);
  return { keyword, typesData, colorsData, sizesData };
};
