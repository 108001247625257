import React, { useState } from 'react';
import { Layer, Rect, Text } from 'react-konva';

import ImgKonva from './ImgKonva';
import ImgCorsKonva from './ImgCorsKonva';

type props = {
  img: string;
  width: number;
  height: number;
  x: number;
  y: number;
  setReady: () => void;
};

const PictureLayer: React.FC<props> = ({
  img,
  width,
  height,
  x,
  y,
  setReady,
}) => {
  const [on, setOn] = useState(true);
  return (
    <Layer>
      <ImgCorsKonva
        imgUrl={img}
        x={x}
        y={y}
        width={width}
        height={height}
        setReady={setReady}
        setOn={setOn}
      />
      {on ? (
        <>
          <Rect x={x} y={y} width={width} height={height} fill="#555" />
          <Text
            x={x}
            y={y}
            width={width}
            height={height}
            fill="white"
            text="Loading..."
            fontSize={15}
            align="center"
            verticalAlign="middle"
          />
        </>
      ) : null}
    </Layer>
  );
};

export default PictureLayer;
{
  /* <ImgKonva imgUrl={img} width={width} height={height} x={x} y={y} /> */
}
