import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { nanoid } from "nanoid";

import { updateAppState } from "../stores/appState";
import type { root } from "../interfaces/slices";
// import './Colmun.scss';

const styles = {
  container: {
    position: "relative",
    width: { xs: "100%", md: "180px" },
    height: { xs: "130px", md: "100%" },
    // py: 2,
    bgcolor: "#151515",
    color: "#fff",
    // paddingTop: '43px',
  },
  text: {
    position: "relative",
    textAlign: "center",
    width: 150,
    py: { xs: 1, md: 3 },
    color: "rgba(248,248,248, 0.95)",
    bgcolor: "#151515",
    fontSize: "0.9em",
    fontWeight: "bold",
    zIndex: 10,
  },
  imgCaseContainer: {
    position: "absolute",
    top: { xs: 2, md: 0 },
    width: { width: "100%", md: "180px" },
    height: { xs: "120px", md: "100%" },
    overflow: "scroll",
    overflowX: { xs: "scroll", md: "hidden" },
    // msOverflowStyle: 'none',
    // scrollbarWidth: 'none',
    // '::-webkit-scrollbar': {
    //   display: 'none',
    //   boxSizing: 'content-box',
    // },
  },
  imgCase: {
    position: "relative",
    display: { xs: "flex", md: "block" },
    flexDirection: { xs: "row", md: "column" },
    width: { width: "10000px", md: "164px" },
    height: { xs: "120px", md: "100%" },
    boxSizing: "border-box",
    p: { xs: 0, md: 2 },
    pt: { xs: 5, md: 5 },
  },
  imgWrapper: {
    width: { xs: "80px", md: "132px" },
    height: { xs: "80px", md: "132px" },
    py: { xs: 0, md: 1 },
    px: { xs: 0.5, md: 0 },
  },
  img: {
    postion: "relative",
    width: { xs: "80px", md: "132px" },
    height: { xs: "80px", md: "132px" },
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    bgcolor: "#151515",
    boxSizing: "border-box",
  },
};

const ImgSelect: React.VFC = () => {
  const { pictureImgs } = useSelector((s: root) => s.appState);
  // console.log("appState", step);
  const dispatch = useDispatch();
  const handleClick = (p: { title: string; img: string; item_id: number }) => {
    // console.log('click');
    const url = `https://enartsu.theshop.jp/items/${p["item_id"]}`;
    // console.log('urlll', url);
    // console.log('title', p);
    dispatch(
      updateAppState({
        pictureImg: p["img"],
        pictureName: p["title"],
        url,
      })
    );
  };
  return (
    <Box className="imgselect-container" sx={styles.container}>
      <Typography sx={styles.text}>作品を選んでください</Typography>
      <Box sx={styles.imgCaseContainer}>
        <Box className="img-case" sx={styles.imgCase}>
          {pictureImgs.map(
            (p: { title: string; img: string; item_id: string }) => (
              <Box sx={styles.imgWrapper} key={nanoid()}>
                <Box
                  className="imgselect-img"
                  onClick={() => handleClick(p)}
                  sx={{ backgroundImage: `url(${p["img"]})`, ...styles.img }}
                />
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ImgSelect;
