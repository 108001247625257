import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import reactImageSize from 'react-image-size';
import type { root } from '../../interfaces/slices';

const usePictureSize = () => {
  const { pictureImg, crop } = useSelector((s: root) => s.appState);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(150);
  const [fx, setX] = useState(0);
  const [fy, setY] = useState(0);
  // const [fZoom, setZoom] = useState(1);
  // console.log('frame', width, height, fx, fy);

  useEffect(() => {
    let unmounted = false;
    const setPsize = async () => {
      if (pictureImg === undefined) {
        return;
      }
      if (unmounted) {
        return;
      }
      try {
        const size = await reactImageSize(pictureImg);
        const cw = crop.width;
        const ch = crop.height;
        // console.log('area', aw, ah);
        const fw = size.width;
        const fh = size.height;
        // console.log('frame-raw', fw, fh);

        let w = cw;
        let h = ch;
        let x = fx;
        let y = fy;
        if (fw > fh) {
          // console.log('wider');
          const zoom = fw / cw;
          w = cw;
          h = fh / zoom;
          x = 0;
          y = (ch - h) / 2;
        } else {
          // console.log('higher');
          const zoom = fh / ch;
          w = fw / zoom;
          x = (cw - w) / 2;
          y = 0;
        }
        setWidth(w);
        setHeight(h);
        setX(x);
        setY(y);
        // setZoom(zoom);
        // console.log('result', w, h);
      } catch {
        console.log('error');
      }
    };
    setPsize();
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [pictureImg, crop]);
  return { fw: width, fh: height, fx, fy };
};

export default usePictureSize;
