import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import type { root } from "../../interfaces/slices";

const usePictureSize = () => {
  const { data } = useSelector((s: root) => s.imgData);
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(200);

  useEffect(() => {
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    console.log("window", ww, wh);

    if (data !== undefined) {
      const img = new Image();
      img.src = data;
      img.onload = function () {
        const rw = img.naturalWidth;
        const rh = img.naturalHeight;
        const zoom = rw / ww;
        const aRatio = rw / rh;
        // console.log('room: ', rw, rh, zoom);
        // console.log('rh: ', rh);
        // const aRatio = rw / rh;

        let w = ww;
        let h = wh;
        console.log("wwhh", w, h);
        if (rw > ww) {
          h = rh / zoom;
        } else {
          h = rh / zoom;
        }
        if (w < 900 && h > wh - 400) {
          console.log("mob");
          w = (wh - 400) * aRatio;
          h = wh - 400;
        }
        if (w >= 900 && h > wh - 400) {
          console.log("widr");
          w = (wh - 400) * aRatio;
          h = wh - 400;
        }
        setWidth(w);
        setHeight(h);
      };
    }
  }, [data]);
  return { rw: width, rh: height };
};

export default usePictureSize;
