import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box, Stack, Typography } from "@mui/material";

// import GetImgs from "../GetImages";
import DropZone from "./DropZone";
import Check from "./Check";
import { updateImgData } from "../../stores/imgData";
import filters from "../../filters.json";
import type { root } from "../../interfaces/slices";

const styles = {
  container: {
    // position: "relative",
    // boxSizing: "border-box",
    width: "100%",
    // maxWidth: { xs: "90vw", md: "sm" },
    maxWidth: { xs: "95vw", md: "sm" },
    mx: "auto",
  },
  textStack: {
    flexDirection: { xs: "colmun", md: "row" },
    justifyContent: "center",
    my: 3,
    text: {
      display: "inline-block",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "游ゴシック体",
      lineHeight: 1.714,
    },
  },
  button: {
    mx: "auto",
    // my: 2,
    color: "rgba(0,0,0,0.55)",
    bgcolor: "#bdbdbd",
    borderRadius: "30px",
    width: { xs: 240, md: 168 },
    height: { xs: 56, md: 56 },
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
};

const Zone: React.FC = () => {
  return (
    <Stack className="step2-container" sx={styles.container} mb={2} spacing={4}>
      <Stack sx={styles.textStack}>
        <Typography sx={styles.textStack.text}>真正面から撮影した</Typography>
        <Typography sx={styles.textStack.text}>
          お部屋の写真をアップロードしてください
        </Typography>
      </Stack>
      <DropZone />
    </Stack>
  );
};

const SiteImgs: React.FC = () => {
  const { data, fileName } = useSelector((s: root) => s.imgData);
  // console.log("imgData", data, fileName);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(updateImgs(imgs));
  // }, [imgs]);

  return (
    <>
      {data === undefined || fileName === undefined ? (
        <Zone />
      ) : (
        <Check data={data} />
      )}
    </>
  );
};

export default SiteImgs;
