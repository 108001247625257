import t0 from '../../imgs/20110/t.jpg';
import b0 from '../../imgs/20110/b.jpg';
import l0 from '../../imgs/20110/l.jpg';
import r0 from '../../imgs/20110/r.jpg';
import tl0 from '../../imgs/20110/tl.jpg';
import tr0 from '../../imgs/20110/tr.jpg';
import bl0 from '../../imgs/20110/bl.jpg';
import br0 from '../../imgs/20110/br.jpg';

import t1 from '../../imgs/20130/t.jpg';
import b1 from '../../imgs/20130/b.jpg';
import l1 from '../../imgs/20130/l.jpg';
import r1 from '../../imgs/20130/r.jpg';
import tl1 from '../../imgs/20130/tl.jpg';
import tr1 from '../../imgs/20130/tr.jpg';
import bl1 from '../../imgs/20130/bl.jpg';
import br1 from '../../imgs/20130/br.jpg';

import t2 from '../../imgs/20510/t.jpg';
import b2 from '../../imgs/20510/b.jpg';
import l2 from '../../imgs/20510/l.jpg';
import r2 from '../../imgs/20510/r.jpg';
import tl2 from '../../imgs/20510/tl.jpg';
import tr2 from '../../imgs/20510/tr.jpg';
import bl2 from '../../imgs/20510/bl.jpg';
import br2 from '../../imgs/20510/br.jpg';

import t3 from '../../imgs/20520/t.jpg';
import b3 from '../../imgs/20520/b.jpg';
import l3 from '../../imgs/20520/l.jpg';
import r3 from '../../imgs/20520/r.jpg';
import tl3 from '../../imgs/20520/tl.jpg';
import tr3 from '../../imgs/20520/tr.jpg';
import bl3 from '../../imgs/20520/bl.jpg';
import br3 from '../../imgs/20520/br.jpg';

export default {
  0: {
    t: t0,
    b: b0,
    l: l0,
    r: r0,
    tl: tl0,
    tr: tr0,
    bl: bl0,
    br: br0,
    width: 20,
  },
  1: {
    t: t1,
    b: b1,
    l: l1,
    r: r1,
    tl: tl1,
    tr: tr1,
    bl: bl1,
    br: br1,
    width: 20,
  },
  2: {
    t: t2,
    b: b2,
    l: l2,
    r: r2,
    tl: tl2,
    tr: tr2,
    bl: bl2,
    br: br2,
    width: 15,
  },
  3: {
    t: t3,
    b: b3,
    l: l3,
    r: r3,
    tl: tl3,
    tr: tr3,
    bl: bl3,
    br: br3,
    width: 15,
  },
};
