import React from 'react';
import useImage from 'use-image';
import { Image } from 'react-konva';

type props = {
  imgUrl: string;

  x?: number;
  y?: number;
  width?: number;
  height?: number;
  id?: string;
  name?: string;
  opacity?: number;
  crop?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  scale?: {
    x: number;
    y: number;
  };
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
  offset?: { x: number; y: number };
  offsetX?: number;
  offsetY?: number;
};

const ImgKonva: React.FC<props> = ({
  imgUrl,
  x,
  y,
  width,
  height,
  id,
  name,
  opacity,
  crop,
  scale,
  scaleX,
  scaleY,
  rotation,
  offset,
  offsetX,
  offsetY,
}) => {
  const [image] = useImage(imgUrl);
  return (
    <Image
      image={image}
      x={x}
      y={y}
      width={width}
      height={height}
      id={id}
      name={name}
      opacity={opacity}
      crop={crop}
      scale={scale}
      scaleX={scaleX}
      scaleY={scaleY}
      rotation={rotation}
      offset={offset}
      offsetX={offsetX}
      offsetY={offsetY}
    />
  );
};
export default ImgKonva;
