import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box, Stack, Typography } from "@mui/material";

// import GetImgs from "../GetImages";
import { updateImgData } from "../../stores/imgData";
import filters from "../../filters.json";
import type { root } from "../../interfaces/slices";

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
    gap: 3,
    // mb: 2,
  },
  imgWrapper: {
    width: "100%",
    height: "100%",
  },
  img: {
    position: "relative",
    maxWidth: { xs: "95vw", md: "sm" },
    height: "35vh",
    mx: "auto",
  },
  textStack: {
    flexDirection: { xs: "colmun", md: "row" },
    justifyContent: "center",
    my: 3,
    text: {
      display: "inline-block",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "游ゴシック体",
      lineHeight: 1.714,
    },
  },
  button: {
    my: 2,
    color: "rgba(0,0,0,0.55)",
    bgcolor: "#bdbdbd",
    borderRadius: "30px",
    width: { xs: 240, md: 168 },
    height: { xs: 56, md: 56 },
    fontSize: "1em",
    fontFamily: "Hiragino Kaku Gothic ProN sans-serif",
    fontWeight: "bold",
  },
};

const Check: React.FC<{ data: string }> = ({ data }) => {
  const { keyword, types, colors, sizes } = useSelector(
    (s: root) => s.appState
  );
  // console.log('words', types, colors, sizes);
  // console.log('datasss', filters);
  const typesData = useMemo(() => {
    const words = Object.keys(types).filter(
      k => types[k] === true && k !== "all"
    );
    return words.map(w => filters.types[w].keyword).toString();
  }, [types]);
  // console.log('typesData', typesData);
  const colorsData = useMemo(() => {
    const words = Object.keys(colors).filter(
      k => colors[k] === true && k !== "all"
    );
    return words.map(w => filters.colors[w].keyword).toString();
  }, [colors]);
  // console.log('colorsData', colorsData);
  const sizesData = useMemo(() => {
    const words = Object.keys(sizes).filter(
      k => sizes[k] === true && k !== "all"
    );
    return words.map(w => filters.sizes[w].keyword).toString();
  }, [sizes]);
  // console.log('sizesData', sizesData);

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      updateImgData({
        data: undefined,
        fileName: undefined,
      })
    );
  };
  return (
    <Stack className="check-container" sx={styles.container}>
      <Stack sx={styles.textStack}>
        <Typography sx={styles.textStack.text}>写真がよろしければ</Typography>
        <Typography sx={styles.textStack.text}>次へ進んでください</Typography>
      </Stack>
      <Box className="check-img-wrapper" sx={styles.imgWrapper}>
        <Box className="check-img" sx={styles.img}>
          <img
            src={data}
            alt="img-check"
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
          />
        </Box>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        sx={styles.button}
        onClick={handleClick}
      >
        写真を変更する
      </Button>
    </Stack>
  );
};

export default Check;
