import React, { useCallback } from 'react';
import { Chip, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../stores/appState';

import InputKeywords from './InputKeywords';

const Keywords: React.VFC = () => {
  const { keywords } = useSelector((s: root) => s.appState);
  // console.log("types", types);
  const dispatch = useDispatch();
  const handleClick = useCallback(
    key => {
      dispatch(
        updateAppState({
          // sizes: { ...check, [key]: !check[key] }
        }),
      );
    },
    [dispatch],
  );

  return (
    <Paper className="choose-work-card">
      <Typography className="work-type">キーワードで選ぶ</Typography>
      <InputKeywords />
    </Paper>
  );
};

export default Keywords;
