import React, { useState, useEffect, useRef, forwardRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stage } from "react-konva";

import useRoomSize from "./useRoomSize";
import useFrameSize from "./useFrameSize";
import usePictureSize from "./usePictureSize";
import RoomLayer from "./RoomLayer";
import PictureLayer from "./PictureLayer";
import FrameLayer from "./FrameLayer";
import { updateResultImg } from "../../stores/resultImg";
import type { root } from "../../interfaces/slices";

// import "./styles.css";
// const roomImg =
//   'https://st.depositphotos.com/1047404/1878/i/950/depositphotos_18782653-stock-photo-empty-brown-room.jpg';
// const lion = 'https://konvajs.org/assets/lion.png';

const downloadURI = (uri: string, name: string) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// const width = 300;
// const height = 300;

type props<T> = {
  ref: {
    current: T | null;
  };
};

const Picture: React.FC<props> = forwardRef((_, ref) => {
  const roomImg = useSelector((s: root) => s.imgData.data);
  const { crop, pictureImg, frameNumber, pictureName } = useSelector(
    (s: root) => s.appState
  );
  // const stageRef = useRef(null);
  const [ready, setReady] = useState(false);
  // console.log('ready', ready);
  // console.log('picture ref', ref);
  // console.log('pictureImg', pictureImg);
  // console.log('frameNumber', frameNumber);
  // console.log('pictureName', pictureName);
  const { rw, rh } = useRoomSize();
  console.log("step4 room size", rw, rh);
  const { fw, fh, fx, fy } = useFrameSize();
  const { pw, ph, px, py } = usePictureSize();

  const zoom = 1;
  // console.log('index room', rw, rh);
  // console.log('index frame', fw, fh, fx, fy);
  // console.log('zoom', zoom);
  // console.log('index picture', pw, ph);
  const pictDim = useMemo(() => {
    let x = crop.x;
    let y = crop.y;
    return {
      x: px + x,
      y: py + y,
      w: pw,
      h: ph,
    };
  }, [crop, pictureImg, pw, ph, px, py]);

  const frameDim = useMemo(() => {
    let x = crop.x;
    let y = crop.y;
    let w = pw;
    let h = ph;
    const padding = (() => {
      if (frameNumber === 0 || frameNumber === 1) {
        return 20;
      }
      if (frameNumber === 2 || frameNumber === 3) {
        return 15;
      }
      return 0;
    })();
    // console.log('IMW', w + padding * 4, pictDim.w);
    return {
      x: fx + x - padding,
      y: fy + y - padding,
      w: w + padding * 2,
      h: h + padding * 2,
    };
  }, [crop, pictureImg, frameNumber, pw, ph]);

  const dispatch = useDispatch();
  const callback = e => {
    // console.log('callback', e);
  };

  useEffect(() => {
    const uri = ref.current.toDataURL();
    // console.log('uriiiiiiiiiiiiiiiiiiiii', uri);
    dispatch(
      updateResultImg({
        data: uri,
        fileName: `${pictureName}.png`,
      })
    );
  }, [ref, ready, frameNumber, crop]);
  // }, [roomImg, crop, pictureImg, frameNumber, roomImg, stageRef]);

  return (
    <Stage width={rw} height={rh} ref={ref}>
      <RoomLayer
        img={roomImg === undefined ? "" : roomImg}
        width={rw}
        height={rh}
      />
      {frameNumber !== 4 ? (
        <FrameLayer
          img={pictureImg === undefined ? "" : pictureImg}
          width={frameDim.w}
          height={frameDim.h}
          x={frameDim.x}
          y={frameDim.y}
          zoom={zoom}
          frameNumber={frameNumber}
        />
      ) : null}
      <PictureLayer
        img={pictureImg === undefined ? "" : pictureImg}
        width={pw}
        height={ph}
        x={pictDim.x}
        y={pictDim.y}
        setReady={setReady}
      />
    </Stage>
  );
});

export default Picture;

// const handleExport = () => {
//   // const uri = stageRef.current.toDataURL();
//   // console.log('uri', uri);
//   console.log('hand');
//   // downloadURI(uri, 'test.png');
// };
