import { createSlice } from "@reduxjs/toolkit";

export type topSnackBar = {
  open: boolean;
  comment?: string;
  severity?: "error" | "info" | "success" | "warning";
};

const initialState: topSnackBar = {
  open: false,
  comment: "",
};

const slice = createSlice({
  name: "appSnackBar",
  initialState,
  reducers: {
    updateSnackBar: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSnackBar } = slice.actions;

export default slice.reducer;
