import React, { ReactNode } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";

import Column from "./Column";
import Header from "./Header";
import theme from "../themes";
// import type { root } from '../interfaces/slices';

const styles = {
  container: {
    position: "relative",
    height: { xs: "100vh", md: "100Vh" },
    bgcolor: "#333",
  },
  header: {
    position: "fixed",
    height: 64,
    width: "100%",
    top: 0,
    zIndex: 10,
    bgcolor: "#fff",
  },
  body: {
    display: "flex",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    pt: "64px",
    // bgcolor: "blue",
    side: {
      display: { xs: "none", md: "block" },
      width: "180px",
      height: "100%",
      // bgcolor: "lightBlue",
    },
    main: {
      width: "100%",
      height: "100%",
      flex: 1,
      bgcolor: "#333",
    },
  },
};

type props = {
  children?: ReactNode;
};

const Layout: React.FC<props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box className="layout-coutainer" sx={styles.container}>
        <Box className="layout-header" sx={styles.header}>
          <Header />
        </Box>
        <Box className="layout-body" sx={styles.body}>
          <Box className="layout-body-side" sx={styles.body.side}>
            <Column />
          </Box>
          <Box className="layout-body-main" sx={styles.body.main}>
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
