import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Stack, Typography } from '@mui/material';
import type { root } from '../../interfaces/slices';
import { updateAppState } from '../../stores/appState';

import aframe from '../../imgs/mihon_d20110.jpg';
import bframe from '../../imgs/mihon_d20130.jpg';
import cframe from '../../imgs/mihon_d20510.jpg';
import dframe from '../../imgs/mihon_d20520.jpg';
// import eframe from '../../imgs/mihon_d20110.jpg';

const styles = {
  stack: {
    flexDirection: 'row',
    gap: 1,
    // p: 2,
  },
  img: {
    width: 32,
    height: 32,
  },
  index: {
    display: { xs: 'none', md: 'block' },
    px: 1,
    lineHeight: '32px',
    color: '#fff',
    border: 'solid 1px lightGray',
    fontSize: '0.6rem',
  },
  none: {
    display: 'flex',
    justifyContent: 'center',
    width: 26,
    height: 26,
    alignItems: 'center',
    color: 'gray',
    bgcolor: '#fff',
    border: 'solid 3px lightGray',
  },
};

const frames = [aframe, bframe, cframe, dframe];

const FrameSelect: React.VFC = () => {
  // const { data } = useSelector((s: root) => s.imgData);
  // console.log("imgData", data);
  const dispatch = useDispatch();
  const handleClick = (i: number) => {
    dispatch(updateAppState({ frameNumber: i }));
  };

  return (
    <Stack sx={styles.stack}>
      <Box>
        <Typography className="" sx={styles.index}>
          額縁を選択
        </Typography>
      </Box>
      {frames.map((f: string, i: number) => (
        <Box sx={styles.img} key={f} onClick={() => handleClick(i)}>
          <img src={f} alt={f} width="100%" height="100%" />
        </Box>
      ))}
      <Box sx={styles.none} onClick={() => handleClick(4)}>
        なし
      </Box>
    </Stack>
  );
};

export default FrameSelect;
