import { createSlice } from '@reduxjs/toolkit';

export type imgData = {
  data: string | undefined;
  fileName: string | undefined;
};

const initialState: imgData = {
  data: undefined,
  fileName: undefined,
};

const slice = createSlice({
  name: 'resultImg',
  initialState,
  reducers: {
    updateResultImg: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { updateResultImg } = slice.actions;

export default slice.reducer;
