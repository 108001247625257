import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Divider, Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import fileDownload from 'js-file-download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLine } from '@fortawesome/free-brands-svg-icons';
// import imageToBase64 from 'image-to-base64/browser';
import { requestPost } from '../../utils/requestMethods';
import { updateAppState } from '../../stores/appState';
// import { updateModal } from '../stores/topData.modal';
import line from '../../imgs/line.png';
import type { root } from '../../interfaces/slices';
import type {
  postRoomImg,
  postRoomImgResponse,
} from '../../interfaces/apiCall';

import './dialogContent.scss';

const styles = {
  dialog: {
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'rgba(0,0,0,0.6)',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  lineLinks: {
    width: 275,
  },
  lineImg: {
    display: { xs: 'none', md: 'block' },
    width: { xs: 0, md: 100 },
    height: { xs: 0, md: 100 },
  },
  lineIcon: {
    position: 'relative',
    width: 70,
    height: 70,
  },
  lineBack: {
    position: 'absolute',
    width: 50,
    height: 60,
    top: 10,
    left: 10,
    bgcolor: '#fff',
    zIndex: -1,
  },
  lineButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  lineButton: {
    color: '#fff',
    bgcolor: '#06c755',
    borderRadius: '30px',
    width: { xs: 180, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: '0.7rem',
    textAlign: 'center',
    lineHeight: '36px',
    fontWeight: 'bold',
  },
  lineTexts: {
    my: 2,
  },

  title: {
    color: '#00CCCC',
    textAlign: 'center',
    fontSize: '1.2rem',
    my: 4,
  },
  text: {
    color: '#fff',
  },
  buttonS: {
    color: '#fff',
    bgcolor: '#348ea8',
    borderRadius: '30px',
    width: { xs: 125, md: 172 },
    height: { xs: 36, md: 36 },
    fontSize: '0.7rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
    hanbai: {
      bgcolor: '#f1cb4e',
    },
    mx: { xs: '1px', md: 1 },
  },
  buttonL: {
    color: '#fff',
    bgcolor: '#348ea8',
    borderRadius: '30px',
    width: { xs: '100%', md: 220 },
    height: { xs: 36, md: 36 },
    fontSize: '0.7rem',
    fontFamily: 'Hiragino Kaku Gothic ProN sans-serif',
    fontWeight: 'bold',
    mx: 1,
  },
  caseS: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'space-around',
    width: '100%',
    my: 4,
  },
  caseL: {
    display: 'flex',
    justifyContent: 'space-between',
    my: 4,
    width: '100%',
  },
  bagian: {
    bgcolor: '#0099CC',
  },
  green: {
    bgcolor: '#339966',
  },
  red: {
    bgcolor: '#990033',
  },
  yellow: {
    color: 'black',
    bgcolor: '#f1cb4e',
  },
  white: {
    color: '#000',
    bgcolor: '#fff',
  },
};

const DialogContent: React.FC<{
  url: string;
  open: boolean;
  setOpen: () => void;
}> = ({ url, open, setOpen }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handlePasing = (step: number) => {
    dispatch(
      updateAppState({
        step,
      }),
    );
    setOpen(false);
  };
  return (
    <Dialog fullWidth open={open} onClose={handleClose} sx={styles.dialog}>
      <Typography sx={styles.title}>画像を保存しました</Typography>
      <Box sx={styles.line}>
        <Box sx={styles.lineImg}>
          <img
            src={line}
            alt="line-contact"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <Box className="line-links" sx={styles.lineLinks}>
          <Box sx={styles.lineButtons}>
            <Box className="line-icon" sx={styles.lineIcon}>
              <Box sx={styles.lineBack} />
              <FontAwesomeIcon
                icon={faLine}
                size="5x"
                color="#06c755"
                swapOpacity
              />
            </Box>
            <a
              href="https://line.me/R/ti/p/%40965witik"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography sx={styles.lineButton}>ともだちになる</Typography>
            </a>
          </Box>
          <Box sx={styles.lineTexts}>
            <Typography sx={styles.text}>LINE公式アカウントで</Typography>
            <Typography sx={styles.text}>
              江夏画廊の最新情報を受け取れます。
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: 'rgba(248,248,248,0.7)' }} />
      <Box sx={styles.caseS}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{ ...styles.buttonS, ...styles.bagian }}
        >
          別の絵画を選ぶ
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handlePasing(0)}
          sx={{ ...styles.buttonS, ...styles.green }}
        >
          最初からやり直す
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{ ...styles.buttonS, ...styles.red }}
        >
          終了する
        </Button>
      </Box>
      <Divider sx={{ borderColor: 'rgba(248,248,248,0.7)' }} />
      <Box sx={styles.caseL}>
        <a
          href="https://www.enartsu.co.jp"
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ ...styles.buttonL, ...styles.white }}
          >
            江夏画廊のホームページ
          </Button>
        </a>
        <a
          href={url}
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ ...styles.buttonL, ...styles.yellow }}
          >
            江夏画廊のオンラインストア
          </Button>
        </a>
      </Box>
    </Dialog>
  );
};

export default DialogContent;
