import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import ImgSelect from "./ImgSelect";
import type { root } from "../interfaces/slices";
// import './Colmun.scss';

const styles = {
  colmun: {
    boxSizing: "border-box",
    bgcolor: "#151515",
    color: "#fff",
    width: 180,
    height: "100%",
    pt: 3,
  },
  number: {
    color: "#151515",
    bgcolor: "#fff",
    width: 67,
    height: 67,
    mt: 2,
    mx: "auto",
    borderRadius: "50px",
    lineHeight: "67px",
    textAlign: "center",
    fontSize: "33px",
  },
  text: {
    width: 100,
    mx: "auto",
    textAlign: "center",
    fontSize: "1em",
    mt: 2,
  },
};

const caption = {
  0: "絵画の種類を選ぶ",
  1: "部屋の画像をアップロード",
  2: "絵画を飾る場所を選択する",
  3: "作品を選んでください",
};

const colmun: React.FC = () => {
  const { step } = useSelector((s: root) => s.appState);
  // console.log("appState", step);
  return (
    <Box className="colmun-area" sx={styles.colmun}>
      {step !== 3 ? (
        <>
          <Box className="colmun-number" sx={styles.number}>
            {step + 1}
          </Box>
          <Typography className="colmun-text" sx={styles.text}>
            {caption[step]}
          </Typography>
        </>
      ) : (
        <ImgSelect />
      )}
    </Box>
  );
};

export default colmun;
