import React from "react";

import Helmet from "./Helmet";
import TopSnackBar from "./TopSnackBar";
import Layout from "./Layout";
import Content from "./Content";
import usePreRender from "../hooks/usePreRender";
import "../themes/main.scss";

const PreRender: React.FC = () => <div>loading...</div>;

const App: React.FC = () =>
  usePreRender(
    PreRender,
    <>
      <Helmet />
      <TopSnackBar />
      <Layout>
        <Content />
      </Layout>
    </>
  );

export default App;
