import React from 'react';
import { Provider } from 'react-redux';

import App from '../components/App';
import usePreRender from '../hooks/usePreRender';
import store from '../stores';

const PreRender: React.FC = () => <div>loading...</div>;

const Index: React.FC = () => {
  return usePreRender(
    PreRender,
    <Provider store={store}>
      <App />
    </Provider>,
  );
};

export default Index;
