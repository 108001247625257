import { createSlice } from "@reduxjs/toolkit";

export type imgData = {
  data: string | undefined;
  fileName: string | undefined;
};

const initialState: imgData = {
  data: undefined,
  fileName: undefined
};

const slice = createSlice({
  name: "imgData",
  initialState,
  reducers: {
    updateImgData: (state, action) => ({ ...state, ...action.payload })
  }
});

export const { updateImgData } = slice.actions;

export default slice.reducer;
