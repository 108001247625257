import React, { useCallback } from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../stores/appState";
import type { root } from "../../interfaces/slices";
import "./Types.scss";

const styles = {
  chip: {
    mx: 1,
    fontSize: "13px",
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1,
    letterSpacing: "0.03em",
    width: "112px",
    height: "33px",
  },
  on: {
    bgcolor: "#348EA8!important",
  },
  off: {
    bgcolor: "#5A5A5A",
  },
};

const typesJa = {
  fukei: "風景",
  kaikei: "海景",
  seibutsu: "静物",
  jinbutsu: "人物",
  doubutsu: "動物",
  cyusyou: "抽象",
  ongaku: "音楽",
  fantasy: "ファンタジー",
};

const Types: React.FC = () => {
  const { types } = useSelector((s: root) => s.appState);
  const dispatch = useDispatch();
  const handleClick = useCallback(
    key => {
      dispatch(
        updateAppState({
          types: { ...types, all: false, [key]: !types[key] },
        })
      );
    },
    [types, dispatch]
  );
  const clickAll = () => {
    dispatch(
      updateAppState({
        types: {
          all: !types.all,
          fukei: !types.all,
          kaikei: !types.all,
          seibutsu: !types.all,
          jinbutsu: !types.all,
          doubutsu: !types.all,
          cyusyou: !types.all,
          ongaku: !types.all,
          fantasy: !types.all,
        },
      })
    );
  };
  return (
    <Paper className="choose-work-card">
      <Typography className="work-type">作品タイプを選ぶ</Typography>
      <Chip
        label="すべて"
        onClick={clickAll}
        sx={{ ...styles.chip, ...(types.all ? styles.on : styles.off) }}
        className="chipAll"
      />
      <div className="work-chipOthers-wrapper">
        {Object.keys(typesJa).map((key: string) => (
          <Chip
            key={key}
            label={typesJa[key]}
            onClick={() => handleClick(key)}
            sx={{ ...styles.chip, ...(types[key] ? styles.on : styles.off) }}
            className="chipOthers"
          />
        ))}
      </div>
    </Paper>
  );
};

export default Types;
