import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import Types from "./Types";
import Colors from "./Colors";
import Sizes from "./Sizes";
import Keywords from "./Keywords";
// import './Index.scss';

const styles = {
  container: {
    position: "relative",
    px: 2,
    mx: "auto",
    mb: 10,
    height: "100%",
    maxWidth: "sm",
  },
  textStack: {
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "center",
    my: { xs: "24px", md: "32px" },
    text: {
      display: "inline-block",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "游ゴシック体",
      lineHeight: 1.714,
    },
  },
  contentStack: {
    pb: 6,
  },
};

const Questionnaries: React.FC = () => {
  return (
    <Box className="step1-container" sx={styles.container}>
      <Stack sx={styles.textStack}>
        <Typography className="decorate-room-text" sx={styles.textStack.text}>
          お部屋に飾りたい
        </Typography>
        <Typography className="decorate-room-text" sx={styles.textStack.text}>
          作品の種類を選んでください
        </Typography>
        <Typography className="decorate-room-text" sx={styles.textStack.text}>
          （絵画レコメンド機能）
        </Typography>
      </Stack>
      <Stack sx={styles.contentStack}>
        <Types />
        <Colors />
        <Sizes />
        <Keywords />
      </Stack>
    </Box>
  );
};

export default Questionnaries;
