import React from "react";
import TextField from "@mui/material/TextField";

type props = {
  id?: string;
  className?: string;
  required?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  size: "medium" | "small";
  rows?: number;
  label: string;
  helperText: string;
  autoComplete?: string;
  value: string | number;
  setValue: (a: string | {}) => void;
  isReady: boolean;
  setIsReady: (v: {}) => void;
  exp: RegExp;
  sx?: {};
  type?: string;
};

const InputValidated: React.VFC<props> = ({
  id,
  className,
  required,
  fullWidth,
  multiline,
  size,
  rows,
  label,
  helperText,
  autoComplete,
  value,
  setValue,
  isReady,
  setIsReady,
  exp,
  sx,
  type
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (exp.test(e.currentTarget.value)) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  };

  const setHelperText = () => {
    if (required && value === "") {
      return "必須入力項目です";
    }
    if (!isReady) {
      return helperText;
    }
    return null;
  };

  return (
    <TextField
      className={`${className} input-validated-text-field`}
      margin="dense"
      id={id}
      label={label}
      required={required}
      fullWidth={fullWidth}
      multiline={multiline}
      size={size}
      rows={rows}
      helperText={setHelperText()}
      autoComplete={autoComplete}
      value={value}
      error={!isReady}
      onChange={handleChange}
      sx={sx}
      type={type}
    />
  );
};

export default InputValidated;
